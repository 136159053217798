<template>
  <div class="left-tabs">
    <!-- logo 信息 -->
    <div class="company-icon">
      <img :src="logo" alt="" @click="handleLogo" class="logo" v-if="userProfile.isExpand" />
      <img :src="tabIcon" alt="" @click="handleLogo" class="tab-icon" v-else />
    </div>
    <!-- tabs导航 -->
    <div class="tabs">

      <div class="tabs-title" v-if="userProfile.isExpand"
        style="font-size: 0.06rem; margin-left: 0.20rem; margin-bottom: 0.04rem; color: rgba(81, 203, 255, 1);">
        常用
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter')">
        <div :class="active === '/userCenter'
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="found" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">发现</span>
        </div>
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter/serverList')">
        <div :class="active === '/userCenter/serverList' ||
          active.includes('/userCenter/serverList')
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="myServer" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">我的游戏服</span>
        </div>
      </div>

      <div class="tabs-title" v-if="userProfile.isExpand"
        style="font-size: 0.06rem;margin: 0.1rem 0 0.04rem 0.20rem; color: rgba(81, 203, 255, 1);">
        个人
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter/personalConfig')">
        <div :class="active === '/userCenter/personalConfig'
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="user" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">个人设置</span>
        </div>
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter/billManage')">
        <div :class="active === '/userCenter/billManage'
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="bill" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">交易订单</span>
        </div>
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter/contactUs')">
        <div :class="active === '/userCenter/contactUs'
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="contact" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">联系我们</span>
        </div>
      </div>

      <div class="tabs-title" v-if="userProfile.isExpand"
        style="font-size: 0.06rem;margin: 0.1rem 0 0.04rem 0.20rem; color: rgba(81, 203, 255, 1);">
        广场
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter/couponSquare')">
        <div :class="active === '/userCenter/couponSquare' ||
          active.includes('/userCenter/couponSquare')
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="square" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">优惠广场</span>
        </div>
      </div>

      <div class="tabs-item" @click="handleTab('/userCenter/financeInfo')">
        <div :class="active === '/userCenter/financeInfo' ||
          active.includes('/userCenter/financeInfo')
          ? 'left-info-active left-info'
          : 'left-info'
          ">
          <span class="left-icon">
            <img :src="litchi" alt="">
          </span>
          <span class="text" v-if="userProfile.isExpand">荔枝广场</span>
        </div>
      </div>

    </div>

    <!-- 帮助中心 -->
    <div class="helper-center">
      <!-- tab 展开状态 -->
      <div class="isexpand" v-if="userProfile.isExpand">
        <div class="imgs">
          <img :src="question" alt="" class="icon">
        </div>
        <div class="text">
          需要帮助嘛？
        </div>
        <el-button type="primary" color="#017EFA" class="btn" @click="handleToWiki">转到帮助中心</el-button>
      </div>

      <!-- 未展开状态 -->
      <div class="noexpand" v-else>
        <div class="imgs" @click="handleToWiki">
          <img :src="question" alt="" class="icon">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import useUserCenterStore from "@/stores/userCenter/userCenter"
import useUserProfileStore from '@/stores/userProfile'

import logo from "@/assets/img/logo.png"
import tabIcon from '@/assets/img/user-center-pic/tab-icon.png'
import found from '@/assets/img/user-center-pic/tabs/found.png'
import myServer from '@/assets/img/user-center-pic/tabs/myServer.png'
import user from '@/assets/img/user-center-pic/tabs/user.png'
import bill from '@/assets/img/user-center-pic/tabs/bill.png'
import contact from '@/assets/img/user-center-pic/tabs/contact.png'
import square from '@/assets/img/user-center-pic/tabs/square.png'
import litchi from '@/assets/img/user-center-pic/tabs/litchi.png'
import question from '@/assets/img/user-center-pic/tabs/question.png'

// 获取收缩栏的状态
const userProfile = useUserProfileStore()

const userCenterStore = useUserCenterStore()

const route = useRoute()

const active = ref(route.path)

// 监听路由变化
watch(
  () => route.path,
  (newPath) => {
    active.value = newPath
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  userCenterStore.getUserMessageInfo()
})

// 点击logo跳转
const handleLogo = () => {
  navigateTo("/")
}

// 点击tab跳转
const handleTab = (path) => {
  active.value = path
  navigateTo(path)
}

// 跳转到 wiki
const handleToWiki = () => {
  window.open('https://wiki.lizigo.cn/', '_blank')
}
</script>

<style lang="less" scoped>
.left-tabs {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .company-icon {
    width: 100%;
    height: 60px;

    display: flex;
    justify-content: center;
    align-items: center;

    .logo {
      width: 140px;
      height: 36px;
      cursor: pointer;
    }

    .tab-icon {
      width: 40px;
      height: 40px;
    }
  }


  .tabs {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    height: 460px;
    margin-top: 20px;

    .tabs-item {
      padding-left: 16px;
      height: 50px;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 16px;
      color: rgba(255, 255, 255, 1);
      cursor: pointer;

      &:hover {
        color: aqua;
      }

      .left-info {
        width: calc(100% - 16px);
        height: 100%;
        display: flex;
        align-items: center;
        // justify-content: center;
        overflow: hidden;

        .left-icon {
          margin-right: 16px;
          margin-left: 18px;

          img {
            width: 24px;
            height: 24px;
          }
        }

        .left-icon-active {
          background-image: linear-gradient(to right, rgba(19, 123, 250, 1) 10%, rgba(221, 65, 188, 1) 100%);
        }

        .text {
          width: 100px;
          overflow: hidden;
          // text-overflow: ellipsis;
          white-space: nowrap;
        }

        .text-active {
          color: #fff;
        }
      }

      .left-info-active {
        border-radius: 6px 0 0 6px;
        background: linear-gradient(to right, rgba(19, 123, 250, 1) 10%, rgba(221, 65, 188, 1) 100%);
        animation: move 0.3s ease;
        overflow: hidden;

        @keyframes move {
          0% {
            width: 0%;
          }

          20% {
            width: 8%;
          }

          34% {
            width: 20%;
          }

          50% {
            width: 40%;
          }

          70% {
            width: 67%;
          }

          100% {
            width: 88%;
          }
        }

        .left-icon {
          margin-right: 20px;
          color: #24daee;

          img {
            width: 24px;
            height: 24px;
          }
        }
      }

    }

    .tabs-item-active {}
  }

  .helper-center {
    position: absolute;
    bottom: 30px;
    // width: 0;
    transition: all .3s ease;

    .isexpand {
      width: 190px;
      height: 90px;
      background-color: #F2F2F2;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      padding-bottom: 8px;

      .imgs {
        position: relative;
        top: -20px;
        width: 36px;
        height: 36px;
        background-color: #aabbfa;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 32px;
          height: 32px;
        }
      }

      .text {
        color: #333;
        font-size: 12px;
        flex: 1;
        position: relative;
        top: -4px;
      }

      .btn {
        font-size: 12px;
      }
    }

    .noexpand {
      .imgs {
        position: relative;
        top: -20px;
        width: 36px;
        height: 36px;
        background-color: #aabbfa;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease;
        cursor: pointer;

        &:hover {
          background-color: rgb(72, 94, 168);
        }

        img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }

}
</style>
